var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "客户拜访",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "拜访历史"
    },
    on: {
      "click-right": _vm.toHistoryView
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    ref: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (clientVisit, index) {
    return _c('div', {
      key: index,
      staticClass: "visit-item"
    }, [_c('van-cell', {
      attrs: {
        "title": "计划拜访日期",
        "value": clientVisit.plan_date
      }
    }), _c('van-cell', {
      attrs: {
        "title": "拜访客户",
        "value": clientVisit.client.name
      }
    }), _c('van-cell', {
      attrs: {
        "value-class": "client-address",
        "title": "客户地址",
        "value": clientVisit.client.address
      }
    }), _c('van-button', {
      attrs: {
        "type": "primary",
        "size": "small",
        "block": "",
        "square": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleVisit(clientVisit);
        }
      }
    }, [_vm._v("完成拜访")])], 1);
  }), 0)], 1), _c('van-dialog', {
    attrs: {
      "title": "填写拜访内容",
      "show-cancel-button": ""
    },
    on: {
      "confirm": _vm.handleVisitFinished
    },
    model: {
      value: _vm.showDialog,
      callback: function callback($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c('van-field', {
    attrs: {
      "label": "拜访内容",
      "type": "textarea",
      "placeholder": "请填写与客户沟通的过程及内容"
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }