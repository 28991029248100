<template>
  <div>
    <my-nav-bar
      title="客户拜访"
      left-text="返回"
      left-arrow
      right-text="拜访历史"
      @click-right="toHistoryView"
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list ref="list" v-model="loading" :finished="finished" @load="getList">
        <div v-for="(clientVisit, index) in list" :key="index" class="visit-item">
          <van-cell title="计划拜访日期" :value="clientVisit.plan_date" />
          <van-cell title="拜访客户" :value="clientVisit.client.name" />
          <van-cell value-class="client-address" title="客户地址" :value="clientVisit.client.address" />
          <van-button type="primary" size="small" block square @click="handleVisit(clientVisit)">完成拜访</van-button>
        </div>
      </van-list>
    </div>
    <van-dialog v-model="showDialog" title="填写拜访内容" show-cancel-button @confirm="handleVisitFinished">
      <van-field v-model="content" label="拜访内容" type="textarea" placeholder="请填写与客户沟通的过程及内容" />
    </van-dialog>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getClientVisits, clientVisitFinished } from '@/api/client-visit'

export default {
  components: { myNavBar },
  data() {
    return {
      list: [],
      listQuery: {
        page: 0
      },
      showEmpty: false,
      loading: false,
      finished: false,
      currentClientVisit: null,
      showDialog: false,
      content: ''
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getClientVisits(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < 10
        this.showEmpty = this.list.length === 0
      })
    },
    refresh() {
      this.listQuery.page = 0
      this.finished = false
      this.showEmpty = false
      this.list = []
      this.$refs.list.check()
    },
    handleVisit(clientVisit) {
      this.currentClientVisit = clientVisit
      this.showDialog = true
    },
    handleVisitFinished() {
      if (this.content.length === 0) {
        this.fail('拜访内容不能为空')
        return
      }
      const data = {
        id: this.currentClientVisit.id,
        content: this.content
      }
      this.beginLoad()
      clientVisitFinished(data).then(res => {
        this.success(res.msg)
        this.content = ''
        this.refresh()
      })
    },
    toHistoryView() {
      this.$router.push({
        path: 'client-visit-history'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.visit-item {
  padding: 10px;
  padding-bottom: 0;
}
.visit-item:last-child {
  padding-bottom: 10px;
}
.client-address {
  flex: 3;
}
</style>
